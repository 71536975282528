<template>
  <div class="mb-4 rounded-lg lg:mb-6">
    <!-- Accordion Header -->
    <div
      class="
        flex
        items-center
        justify-between
        w-full
        px-5
        py-3
        lg:px-6 lg:py-4
        transition-all
        duration-700
        rounded-t-lg
        cursor-pointer
        shadow-lg
      "
      :class="{
        'rounded-b-lg': !openAccordion,
        'bg-sky-550 sm:hover:bg-sky-650': props.colorTheme === 'sky',
        'bg-blue-550 sm:hover:bg-blue-650': props.colorTheme === 'blue',
        'bg-indigo-550 sm:hover:bg-indigo-650': props.colorTheme === 'indigo',
      }"
      @click="openAccordion = !openAccordion"
    >
      <h3
        class="
          text-sm
          font-bold
          tracking-wider
          sm:text-base
          md:text-lg
          lg:text-xl
        "
        :class="{
          'text-sky-50': props.colorTheme === 'sky',
          'text-blue-50': props.colorTheme === 'blue',
          'text-indigo-50': props.colorTheme === 'indigo',
        }"
      >
        {{ props.university }}
      </h3>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 transition duration-700 transform lg:w-7 lg:h-7"
        :class="{ 'rotate-90': !openAccordion }"
        :fill="
          { sky: '#F0F9FF', blue: '#EFF9FF', indigo: '#EEF2FF' }[
            props.colorTheme
          ]
        "
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <polyline
          points="96 48 176 128 96 208"
          fill="none"
          :stroke="
            { sky: '#F0F9FF', blue: '#EFF9FF', indigo: '#EEF2FF' }[
              props.colorTheme
            ]
          "
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></polyline>
      </svg>
    </div>
    <!-- Accordion Content -->
    <!-- Different maximum height depending on number of courses eligible -->
    <div
      class="
        overflow-hidden
        transition-all
        rounded-b-lg
        shadow-lg
        bg-gradient-to-b
      "
      :class="{
        'from-sky-200 to-sky-50': props.colorTheme === 'sky',
        'from-blue-200 to-blue-50': props.colorTheme === 'blue',
        'from-indigo-200 to-indigo-50': props.colorTheme === 'indigo',
        'max-h-128 duration-300':
          openAccordion &&
          props.university === 'Singapore Management University',
        'max-h-400 duration-700':
          openAccordion &&
          (!props.filterDefault || props.eligibleCourses.length > 45) &&
          props.university === 'National University of Singapore',
        'max-h-480 duration-700':
          openAccordion &&
          (!props.filterDefault || props.eligibleCourses.length > 22) &&
          props.university === 'Nanyang Technological University',
        'max-h-200 duration-500':
          (openAccordion &&
            props.filterDefault &&
            props.eligibleCourses.length <= 22 &&
            props.university === 'Nanyang Technological University') ||
          (openAccordion &&
            props.filterDefault &&
            props.eligibleCourses.length <= 45 &&
            props.university === 'National University of Singapore'),
        'max-h-0 duration-500': !openAccordion,
      }"
    >
      <div class="flex flex-col gap-2 px-4 py-2" v-if="renderFaculties.length">
        <app-gpa-accordion-section
          v-for="[faculty, facultyCourses] of renderFaculties"
          :faculty="faculty"
          :eligible-courses="props.eligibleCourses"
          :facultyCourses="facultyCourses"
          :sortDefault="props.sortDefault"
          :colorTheme="props.colorTheme"
          :key="props.university + faculty"
        />
      </div>
      <div
        v-else
        class="flex items-center justify-center gap-1 py-6 text-blue-gray-800"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <circle
            cx="128"
            cy="128"
            r="96"
            fill="none"
            stroke="currentColor"
            stroke-width="24"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></circle>
          <line
            x1="128"
            y1="80"
            x2="128"
            y2="132"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <circle cx="128" cy="172" r="16"></circle>
        </svg>
        <p class="text-lg font-bold text-center">No eligible courses</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import AppGpaAccordionSection from "@/components/AppGpaAccordionSection.vue";

const props = defineProps({
  university: {
    type: String,
    required: true,
  },
  // Keys are faculties set to the array of course IDs for that faculty.
  eligibleCourses: {
    type: Array,
    required: true,
  },
  uniCourses: {
    type: Array,
    required: true,
  },
  filterDefault: {
    type: Boolean,
    required: true,
  },
  sortDefault: {
    type: Boolean,
    required: true,
  },
  colorTheme: {
    type: String,
    required: true,
  },
});

const openAccordion = ref(false);

const renderFaculties = computed(() =>
  !props.filterDefault
    ? props.uniCourses
    : props.uniCourses
        .map(([_, facultyCourses]) => {
          // Remove all non-eligible courses.
          return [
            _,
            facultyCourses.filter(
              (id) => props.eligibleCourses.indexOf(id) !== -1
            ),
          ];
        })
        // eslint-disable-next-line no-unused-vars
        .filter(([_, facultyCourses]) => {
          // Remove all faculties with no courses with COP
          return facultyCourses.length;
        })
);
</script>
