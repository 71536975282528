<template>
  <p
    class="text-sm font-semibold underline mb-0.5 lg:text-base"
    :class="{
      'text-sky-900': props.colorTheme === 'sky',
      'text-blue-900': props.colorTheme === 'blue',
      'text-indigo-900': props.colorTheme === 'indigo',
    }"
  >
    {{ props.faculty }}
  </p>
  <div v-for="id in renderCourses" :key="id">
    <div
      class="mb-1 ml-1"
      :class="{ 'text-red-600': props.eligibleCourses.indexOf(id) === -1 }"
    >
      <router-link
        :to="{ path: `/courses/${id}` }"
        class="text-sm sm:hover:underline sm:text-base"
      >
        {{ courses[id].major }} -
        <span class="font-medium">({{ courses[id].gpa }})</span>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import courses from "@/data/courses.json";

const props = defineProps({
  faculty: {
    type: String,
    required: true,
  },
  eligibleCourses: {
    type: Array,
    required: true,
  },
  facultyCourses: {
    type: Array,
    required: true,
  },
  sortDefault: {
    type: Boolean,
    required: true,
  },
  colorTheme: {
    type: String,
    required: true,
  },
});

const sortCoursesGpa = function (inputCourses) {
  // Sort by course GPA, if not, sort alphabetically
  return inputCourses.sort((a, b) =>
    courses[b].gpa - courses[a].gpa
      ? courses[b].gpa - courses[a].gpa
      : courses[a].major.localeCompare(courses[b].major)
  );
};

const sortCoursesAlpha = function (inputCourses) {
  return inputCourses.sort((a, b) =>
    courses[a].major.localeCompare(courses[b].major)
  );
};

const renderCourses = computed(() =>
  props.sortDefault
    ? sortCoursesGpa(props.facultyCourses)
    : sortCoursesAlpha(props.facultyCourses)
);
</script>
