<template>
  <main>
    <!-- Courses Header -->
    <the-heading background-image="study">
      <template #header>
        <p
          class="
            mb-2
            text-sm
            tracking-wider
            md:text-base
            xl:text-lg
            text-blue-gray-50
          "
        >
          SGUniStats | Poly
        </p>
        <h1
          class="
            text-2xl
            font-semibold
            leading-7
            md:text-3xl
            xl:text-4xl
            text-blue-gray-50
          "
        >
          Assess your chances of a successful&nbsp;application
        </h1>
      </template>
    </the-heading>

    <!-- Poly GPA Slider -->
    <section class="container px-3 pt-4 pb-16 mx-auto sm:px-0 sm:pt-8">
      <div class="flex items-center gap-2 mb-2 text-blue-gray-800">
        <h2 class="text-2xl font-bold">Poly GPA & IGP Comparison</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-7 h-7"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <rect
            x="32"
            y="48"
            width="192"
            height="160"
            stroke-width="24"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
            rx="8"
            transform="translate(256) rotate(90)"
          ></rect>
          <line
            x1="92"
            y1="76"
            x2="164"
            y2="76"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <circle cx="104" cy="128" r="16"></circle>
          <circle cx="152" cy="128" r="16"></circle>
          <circle cx="104" cy="176" r="16"></circle>
          <circle cx="152" cy="176" r="16"></circle>
        </svg>
      </div>
      <h3 class="mb-6 font-medium leading-5">
        Compare your rank points with the latest 2022 IGPs (10th percentile
        GPA).
      </h3>
      <div class="mb-8">
        <label
          class="
            inline-block
            bg-gradient-to-br
            from-blue-100
            to-sky-200
            p-4
            rounded
            shadow-lg
            w-11/12
            sm:w-96
            font-semibold
          "
        >
          <span class="text-blue-gray-700">Your GPA: {{ gpa }}</span>
          <input
            class="block px-0.5 w-full"
            type="range"
            v-model="gpa"
            min="3"
            max="4"
            step="0.01"
          />
        </label>
      </div>
      <h3 class="pt-2 mb-2 text-xl font-bold text-blue-gray-800">Results</h3>
      <p class="text-lg">
        Your GPA:
        <span class="font-semibold"> {{ gpa }}</span>
      </p>
      <p class="mb-8 text-lg">
        Estimated Eligible Courses:
        <span class="font-semibold">
          {{ eligibleCoursesCount }}
          {{
            !eligibleCoursesCount ? "(Consider Aptitude-Based Admission)" : ""
          }}</span
        >
      </p>
    </section>

    <app-hr />

    <section class="container px-3 pt-16 pb-16 mx-auto sm:px-0">
      <h2 class="mb-1 text-2xl font-bold text-blue-gray-800">
        Course Eligibility Estimate
      </h2>
      <p class="mb-6 font-medium leading-5">
        Find out which courses are likely to accept your application based on
        the IGP. Updated for AY2021/2022. Note that courses with no IGP are not
        listed.
      </p>

      <div class="flex flex-col gap-4 gap-5 mb-8 lg:flex-row md:flex">
        <app-toggle
          v-model:toggleState="filterDefault"
          toggleLabel="Filter"
          defaultValue="Likely Eligible"
          otherValue="All Courses"
        />
        <app-toggle
          v-model:toggleState="sortDefault"
          toggleLabel="Sort"
          defaultValue="By GPA"
          otherValue="Alphabetical"
        />
      </div>

      <p class="mb-2 text-sm leading-5 text-blue-gray-600 sm:text-base">
        The lists below automatically update with your GPA. Click on any of the
        courses to learn more.
      </p>

      <app-gpa-accordion
        v-for="(university, index) in universitiesWithGPA"
        :university="university"
        :eligible-courses="eligibleCourses"
        :uni-courses="filterUniCop(sortedCourses[university])"
        :filter-default="filterDefault"
        :sort-default="sortDefault"
        :color-theme="accordionColors[index]"
        :key="university"
      />
    </section>

    <app-hr />

    <app-list-gpa v-once />

    <app-hr />

    <app-cards desc="More Tools" :hiddenCards="['poly']" />

    <app-hr />

    <app-notes page="calculator" />
  </main>
</template>

<script setup>
import { ref, computed } from "vue";

import TheHeading from "@/components/TheHeading.vue";
import AppToggle from "@/components/AppToggle.vue";
import AppGpaAccordion from "@/components/AppGpaAccordion.vue";
import AppListGpa from "@/components/AppListGpa.vue";
import AppNotes from "@/components/AppNotes.vue";
import AppCards from "@/components/AppCards.vue";
import AppHr from "@/components/AppHr.vue";

import gpas from "@/data/gpas.json";
import courses from "@/data/courses.json";
import sortedCourses from "@/data/sortedCourses.json";

const accordionColors = ["sky", "blue", "indigo"];
const universitiesWithGPA = [
  "National University of Singapore",
  "Nanyang Technological University",
  "Singapore Management University",
];

const filterDefault = ref(true);
const sortDefault = ref(true);

const gpa = ref(4);

// eslint-disable-next-line no-unused-vars

const eligibleCourses = computed(() =>
  gpas
    .filter((course) => course.gpa && +gpa.value >= course.gpa)
    .map((course) => course.id)
);
const eligibleCoursesCount = computed(() => eligibleCourses.value.length);

const filterUniCop = (uniCourses) => {
  return (
    Object.entries(uniCourses)
      // eslint-disable-next-line no-unused-vars
      .map(([_, facultyCourses]) => {
        // Remove all courses with no gpa
        return [_, facultyCourses.filter((id) => courses[id].gpa)];
      })
      // eslint-disable-next-line no-unused-vars
      .filter(([_, facultyCourses]) => {
        // Remove all faculties with no courses with COP
        return facultyCourses.length;
      })
  );
};
</script>
